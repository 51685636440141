<template>
	<div class="page_container">
		<div class="page_content">
			<img class="page_content_flag" src="@/assets/images/-14810.jpg" alt="" />
			<div class="page_content_divider" />
			<div class="page_content_about_more_bg">
				<div class="page_content_about_img_bg">
					<img class="page_content_about_img" src="@/assets/images/10218148.jpg" alt="" />
				</div>
				<div class="page_content_about_more">
					<div class="page_title_bg">
						<div class="page_title_flag" />
						<div class="page_title">关于我们</div>
					</div>
					<div class="page_content_about_desc">
						<p>国广（上海）信息科技有限公司（简称：国广财经）致力于成为中国最具规模和权威的全球财经新闻及商业数据服务商。</p>
						<p>国广财经立足于国广环球传媒控股有限公司旗下雄厚的媒体和资讯实力，利用云计算、大数据、人工智能等先进技术，聚合“媒体、金融、科技”的力量，搭建金融信息服务平台</p>
					</div>
					<el-button class="page_content_about_btn" type="primary" plain @click="handleAbout">了解更多>>></el-button>
				</div>
			</div>
			<div class="page_content_news_bg">
				<div class="page_content_news_title">新闻资讯</div>
				<div class="page_content_news_divider_bg">
					<div class="page_content_news_divider" />
					<div class="page_content_news_divider" />
				</div>
				<div class="page_content_news_imgs_bg">
					<a class="page_content_news_img_bg" v-for="info of items" :key="info.id" @click.stop="handleNewItem(info.id)">
						<el-image class="page_content_news_img" :src="imgUrl(info.img)" fit="cover"></el-image>
						<div class="page_content_news_title_bg">
							<span class="page_content_news_title">{{ info.title }}</span>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { news_info } from '@/utils/info.js';

export default {
    name: 'Home',
    props: {},
    watch: {},
    data() {
        return {};
    },
	computed:{
		items: () => news_info,
		imgUrl: () => (img) => {
			return require(`@/assets/images/${img}`);
		},
	},
    mounted() {

    },
    created() {
    },
    methods: {
		handleAbout(){
			this.$router.push("/about");
		},
		handleNewItem(id){
			this.$router.push(`/detail/${id}`);
		}
	},
};
</script>

<style lang="scss" scoped>
.page_container{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	.page_content{
		margin-top: 70px;
		flex-grow: 1;
		width: 100%;
		align-self: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		.page_content_flag{
			margin-left: 10px;
			width: 72px;
			height: 70px;
		}
		.page_content_divider{
			background-color: white;
			border-bottom: 1px solid #ddd;
			width: 100%;
			height: 20px;
			margin-top: -20px;
		}
		.page_content_about_more_bg{
			margin-bottom: 40px;
			width: 1200px;
			display: flex;
			align-items: flex-start;
			.page_content_about_img_bg{
				margin: 29px 36px 0 0;
				width: 550px;
				height: 412px;
				border: 2px dashed #ddd;
				display: flex;
				align-items: center;
				justify-content: center;
				.page_content_about_img{
					width: 544px;
					height: 406px;
				}
			}
			.page_content_about_more{
				.page_title_bg{
					margin-top: 36px;
					display: flex;
					align-items: center;
					.page_title_flag{
						margin-right: 5px;
						width: 22px;
						height: 64px;
						background-color: #409eff;
					}
					.page_title{
						font-size: 42px;
						color:#444444; 
						font-family:Microsoft YaHei;
						font-weight: bold;
					}
				}
				.page_content_about_desc{
					margin: 20px 30px 20px 0;
					font-family: 'Microsoft YaHei';
					color: #000000;
					font-size: 22px;
					font-weight: normal;
					font-style: normal;
					text-decoration: none;
					text-align: left;
					line-height: 40px;
					word-wrap: break-word;
					word-break: normal;
				}
				.page_content_about_btn{
					width: 164px;
					height: 44px;
					margin-left: 20px;
				}
			}
		}
		.page_content_news_bg{
			width: 100%;
			height: 540px;
			margin-bottom: 30px;
			background-image: url("../../assets/images/-14764.jpg");
			display: flex;
			flex-direction: column;
			align-items: center;
			.page_content_news_title{
				font-family: "Microsoft YaHei";
				font-weight: bold;
				font-size: 36px;
				text-align: center;
				color: rgb(127, 140, 141);
				margin: 25px 0 15px;
			}
			.page_content_news_divider_bg{
				display: flex;
				.page_content_news_divider{
					height: 1px;
					width: 143px;
					margin: 0 26px 0;
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-bottom-color: #444444;
				}
			}
			.page_content_news_imgs_bg{
				margin-top: 70px;
				width: 1200px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				.page_content_news_img_bg{
					width: 268px;
					height: 272px;
					position: relative;
					.page_content_news_img{
						width: 268px;
						height: 272px;
					}
					.page_content_news_title_bg{
						background-color: rgba(0, 0, 0, 0.6);
						width: 268px;
						position: absolute;
						bottom: 0;
						.page_content_news_title{
							display: inline-block;
							width: calc(100% - 10px);
							margin:0;
							padding: 0 5px;
							font-size: 14px;
							color: #fff;
							text-align: left;
							font-weight: bold;
							font-style: normal;
							text-decoration: none;
							font-family: 'Microsoft YaHei';
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
		
	}
}
</style>
